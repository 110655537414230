import React, { useContext, useEffect } from "react";
import { StateContext } from "./StateContainer";
import Moment from "react-moment";
import AvatarPink from "../img/avatarPink.png";
import MessaggioUtente from "./NuovaChat/MessaggioUtente";
import PrimoMessaggioBot from "./NuovaChat/PrimoMessaggioBot";
import MessaggioBot from "./NuovaChat/MessaggioBot/MessaggioBot";
import MacchinaDaScrivere from "./MacchinaDaScrivere";
import FormGetInfoUser from "./FormGetInfoUser/FormGetInfoUser";

const NuovaChat = ({
  progetto,
  chat,
  firstMessageDate,
  totalChat,
  ControlsBeforeSendingMessage,
}) => {
  const { dettagliProgetto, setDettagliProgetto, caricamento } =
    useContext(StateContext);

  // Colore esadecimale

  var hexPrimaryColor = null;
  var primaryRed = null;
  var primaryGreen = null;
  var primaryBlue = null;

  var primaryRGBA = null;

  if (dettagliProgetto) {
    hexPrimaryColor = dettagliProgetto.primaryColor;

    if (hexPrimaryColor) {
      // Estrai i componenti di rosso, verde, blu dall'hex
      primaryRed = parseInt(hexPrimaryColor.slice(1, 3), 16);
      primaryGreen = parseInt(hexPrimaryColor.slice(3, 5), 16);
      primaryBlue = parseInt(hexPrimaryColor.slice(5, 7), 16);

      primaryRGBA = `rgba(${primaryRed}, ${primaryGreen}, ${primaryBlue}, 0.1)`;
    }
  }

  useEffect(() => {
    const primoMessaggio = document.getElementById("primoMessaggio");
    if (!caricamento) {
      setTimeout(() => {
        primoMessaggio.style.opacity = "1";
        primoMessaggio.style.marginTop = "0px";
      }, 200);
    }
  }, []);

  return (
    <>
      {/* Il primo Messaggio dell'Assistente Virtuale */}
      <PrimoMessaggioBot
        totalChat={totalChat}
        primaryRGBA={primaryRGBA}
      ></PrimoMessaggioBot>

      {/* Inizia la Chat */}

      {chat.map((row, index) => {
        return (
          <React.Fragment key={index}>
            {/* Messaggio dell'Utente */}
            <MessaggioUtente row={row}></MessaggioUtente>

            {/* Messaggio dell'Assistente */}
            {!row.faq_answer ? (
              <MessaggioBot row={row} primaryRGBA={primaryRGBA}></MessaggioBot>
            ) : (
              <MacchinaDaScrivere
                text={row.botMessage.text}
                row={row}
                primaryRGBA={primaryRGBA}
                index={index}
                ControlsBeforeSendingMessage={ControlsBeforeSendingMessage}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default NuovaChat;
